<template>
  <div>
    <doctor-table
      :canDelete="true"
      viewRoute="AdminDoctorEdit"
      :canCreate="true"
      viewDetailRoute="SubAdminDoctorDetail"
      createRoute="AdminDoctorCreate"
    />
  </div>
</template>

<script>
import doctorTable from "../../components/doctors/doctorTable.vue";

export default {
  components: {
    doctorTable
  },
  data: () => ({})
};
</script>
